<template>
  <AppPageCard>
    <template #card-title> Staff Individual Yearly Attendance </template>
    <div>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ $t("EMPLOYEE.EMP") }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getTitle() }}
      </span>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ "Attendance Year" }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getYear() }}
      </span>
      <IndividualEmployeeAttendanceYearlyPageTable
        :is-busy="isBusy"
        :items="items"
        @showIndividualAttendance="openModal"
      ></IndividualEmployeeAttendanceYearlyPageTable>
    </div>
    <StaffIndividualMonthlyAttendanceModal
      :employee="employee"
      :date="date"
      :date-info="dateInfo"
    ></StaffIndividualMonthlyAttendanceModal>
  </AppPageCard>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import IndividualEmployeeAttendanceYearlyPageTable from "@/modules/school/components/shared/management/attendance/table/IndividualEmployeeAttendanceYearlyPageTable";
import StaffIndividualMonthlyAttendanceModal from "@/modules/school/components/management/attendance/staff/StaffIndividualMonthlyAttendanceModal";
export default {
  name: "StaffIndividualYearlyAttendancePage",
  components: {
    StaffIndividualMonthlyAttendanceModal,
    IndividualEmployeeAttendanceYearlyPageTable,
    AppPageCard,
  },
  data() {
    return {
      isBusy: false,
      date: new Date(dayjs().format()),
      employee: {},
      dateInfo: {},
    };
  },
  mounted() {
    this.setDate();
    this.fetch();
  },
  methods: {
    getTitle() {
      return this.$route.query.name;
    },

    getYear() {
      return this.$route.query.year;
    },

    setDate() {
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: this.getYear(),
      });

      this.$store.dispatch("changeStaffEmployeeYearlyAttendanceListDate", {
        date: date.format(),
      });
    },

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffIndividualEmployeeYearlyAttendanceList", {
          id: this.$route.query.id,
        })
        .finally(() => (this.isBusy = false));
    },

    openModal(data) {
      console.log("Data when open modal: ", data);
      const item = data.item;
      console.log("Selected item ", item);
      this.employee = item.employee;
      console.log("Checking employee", this.employee);

      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: this.getYear(),
        month: data.item.month_number - 1,
      });
      this.date = dayjs(date).toDate();

      console.log("Selected date : ", this.date);

      this.$store.commit("setStaffIndividualAttendancePage", 1);

      this.$store.dispatch("fetchStaffIndividualAttendance", {
        id: this.employee.id,
        date: this.date,
        date_type: 1,
      });

      this.dateInfo = {
        date: this.date,
        date_type: 1,
      };

      this.$bvModal.show("employee-monthly-attendance-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffIndividualEmployeeYearlyAttendanceList",
    }),
  },
};
</script>

<style scoped></style>
